import StatIds from "./StatIds";
import datacronAffix from "./gen_datacron_affix.json";
import datacronTemplate from "./gen_datacron_template.json";
import datacronSet from "./gen_datacron_set.json";

export interface IDatacron
{
    id: string;
    setId: number;
    templateId: string;
    tag: string[];
    affix: IDatacronAffix[];
    locked: boolean;
    rerollIndex: number;
    rerollOption: IDatacronAffix[];
    rerollCount: number;
}

export interface IDatacronAffix
{
    targetRule: string;
    abilityId: string;
    statType: StatIds;
    statValue: number;
    tag: string[];
    requiredUnitTier: number;
    requiredRelicTier: number;
    scopeIcon: string;
}

export interface IDatacronAffixTemplateSet
{
    id: string;
    affix: {
        abilityId: string,
        targetRule: string,
        statType: number,
        statValueMin: number,
        statValueMax: number,
        minTier: number,
        maxTier: number,
        tag: any[],
        scopeIcon: string
    }[];
}

export interface IDatacronPlanTarget
{
    name: string;
    l3Affixes: string[];
    l6Affixes: string[];
    l9Affixes: string[];
    priority: "High" | "Medium" | "Low" | "Nice to have";
    quantity: number;
    secondaryStats: {
        stat: StatIds;
        minValue: number;
    }[];
    notes: string;
}

export interface IDatacronPlanSet
{
    setId: number;
    targets: IDatacronPlanTarget[];
}

export interface IDatacronPlan
{
    sets: IDatacronPlanSet[];
}

export function findMatchingAffix(affix: IDatacronAffix): typeof datacronAffix[keyof typeof datacronAffix][number] | null
{
    for (const items of Object.values(datacronAffix)) 
    {
        for (const item of items)
        {
            if (item.targetRule === affix.targetRule && item.abilityId === affix.abilityId)
            {
                return item;
            }
        }
    }
    return null;
}

export function rollupStatAffixes(datacron: IDatacron): Map<StatIds, number>
{
    let ret = new Map<StatIds, number>();

    for (const affix of datacron.affix) 
    {
        if (affix.statType === 0)
            continue;
        ret.set(affix.statType, (ret.get(affix.statType) || 0) + affix.statValue);
    }

    return ret;
}

export function getAffixesForSet(setId?: number): typeof datacronAffix[keyof typeof datacronAffix][number][]
{
    let ret: typeof datacronAffix[keyof typeof datacronAffix][number][] = [];

    let templates = new Set<string>();
    for (const items of Object.values(datacronTemplate)) 
    {
        if (setId !== undefined && items.setId !== setId)
            continue;
        items.tier.forEach(t => t.affixTemplateSetId.forEach(a => templates.add(a)));
    }

    for (const affixName of templates.values())
    {
        if (isAffix(affixName))
        {
            let affix = datacronAffix[affixName];
            affix.forEach(a => {
                if (condensedDatacons[a.abilityId])
                    return;
                
                ret.push(a);
            });
        }
    }

    return ret;
}

export function affixKeyToShortName(key: string): string
{
    let parts = key.split("/", 2);
    for (const affixList of Object.values(datacronAffix))
    {
        for (const affix of affixList)
        {
            if (affix.abilityId === parts[0] && affix.targetRule === parts[1])
                return affix.shortText;
        }
    }
    return key;
}

export function datacronToString(datacron: IDatacron): string
{
    if (datacron.affix.length < 3)
    {
        return `L${datacron.affix.length}`;
    }
    let affixIndex = 2;
    if (datacron.affix.length >= 9)
    {
        affixIndex = 8;
    }
    else if (datacron.affix.length >= 6)
    {
        affixIndex = 5;
    }
    let aff = findMatchingAffix(datacron.affix[affixIndex]);
    if (!aff)
    {
        return `L${datacron.affix.length}`;
    }
    return `L${datacron.affix.length} - ${aff.shortText}`;
}

function isAffix(id: string): id is keyof typeof datacronAffix
{
    return id in datacronAffix;
}

export function isSet(setId: string): setId is keyof typeof datacronSet
{
    return setId in datacronSet;
}

export function canReroll(datacron: IDatacron): boolean
{
    return datacron.affix.length > 2;
}

export function canRerollAffix(datacron: IDatacron, index: number): boolean
{
    if (datacron.affix.length <= 2)
        return false;
    if (datacron.affix.length <= 5)
    {
        return (index === 3);
    }
    if (datacron.affix.length <= 8)
    {
        return (index === 6);
    }
    return (datacron.affix.length === 9);
}

export function getUpgradeCost(datacron: IDatacron): Map<string, number>
{
    let ret = new Map<string, number>();
    if (datacron.affix.length === 9)
        return ret;
    
    let setId = datacron.setId.toString();
    if (!isSet(setId))
        return ret;
    
    let setData = datacronSet[setId];
    let recip = setData.tier[datacron.affix.length + 1].upgradeRecipe;
    if (!recip)
        return ret;
    for (let ing of recip.ingredients)
    {
        ret.set(ing.id, ing.minQuantity);
    }
    return ret;
}

export function canUpgrade(datacron: IDatacron, materials: Map<string, number>, datacronCredits: number): boolean
{
    let cost = getUpgradeCost(datacron);
    if (cost.size === 0)
        return false;
    
    for (let ing of cost.entries())
    {
        if (ing[0] === "DATACRON_CURRENCY")
        {
            if (datacronCredits < ing[1])
                return false;
            continue;
        }
        let have = materials.get(ing[0]) || 0;
        if (have < ing[1])
            return false;
    }
    return true;
}

export function getDismantleResults(datacron: IDatacron): Map<string, number>
{
    let ret = new Map<string, number>();
    if (datacron.affix.length === 9)
        return ret;
    
    let setId = datacron.setId.toString();
    if (!isSet(setId))
        return ret;
    
    let setData = datacronSet[setId];
    let recip = setData.tier[datacron.affix.length].dustGrantRecipe;
    if (!recip)
        return ret;
    for (let ing of recip.ingredients)
    {
        ret.set(ing.id, ing.minQuantity);
    }
    return ret;
}

export const condensedDatacons: Record<string, string> = {
    "datacron_character_baylanskoll_002": "datacron_character_baylanskoll_001",
    "datacron_character_baylanskoll_003": "datacron_character_baylanskoll_001",
    "datacron_character_hunters3_002": "datacron_character_hunters3_001",
    "datacron_character_hunters3_003": "datacron_character_hunters3_001",
    "datacron_character_appo_002": "datacron_character_appo_001",
    "datacron_character_appo_003": "datacron_character_appo_001",
}

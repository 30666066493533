import React from "react";
import {BasicLayout} from '../../../layouts/BasicLayout';
import IPageProps from "../../IPageProps";
import {observer} from "mobx-react";
import LoadingSpinner from "../../../components/Loading/LoadingSpinner";
import tbGameData from "../../../model/gen_tb.json";
import roteData from "../../../model/gen_tb.json";

import styles from "./styles/Command.module.scss";
import {action, observable, runInAction, toJS} from "mobx";
import TBPlayers from "../TBPlayers/tb-players";
import {
	Alert,
	Button,
	Card,
	Collapse, Divider,
	Dropdown,
	Input, Menu,
	message,
	Modal,
	Popconfirm,
	Select,
	Tabs,
	Typography
} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarDay, faSave, faSyncAlt, faTrash} from "@fortawesome/pro-duotone-svg-icons";
import {TBController} from "../TBController";
import i18next from "i18next";
import {
	TBReconZoneStatus,
	TBRoteAllZones,
	TBRoteDayZoneStatus, TBRoteLocationZoneStatus, TBRoteZoneData,
	TBSavedMessages, TBZoneCommands,
	TZoneType
} from "../../../model/TerritoryBattleData";
import BaseAPI from "../../../service/BaseAPI";
import {faAngleDown, faArrowAltLeft} from "@fortawesome/pro-solid-svg-icons";
import {TerritoryBattleDefinition} from "../../../model/TerritoryBattleGameData";
import moment from "moment";
import {IROTEZoneType, ITBRotePlanetsData, TBRoteLocationZoneList} from "../../../model/ROTETBData";
import CCPlanetSelection from "./components/CCPlanetSelection";
import uuid from "../../../utils/uuid";

const {TabPane} = Tabs;
const {Panel} = Collapse;
const {Title} = Typography;

const GUILD_TB_SAVED_MESSAGES = "guildTbRoteSavedMessages";
const GUILD_ALL_TB_COMMAND_CENTER = "guildAllTbRoteCommandCenter";

export interface ICommandCenterROTEProps extends IPageProps {
	tb?: string;
	back: () => void;
}

interface ISpecialText {
	id: string;
	text: string;
}

@observer
export default class CommandCenterROTE extends React.Component<ICommandCenterROTEProps> {
	@observable errorGuildFetch?: string = undefined;
	@observable tbAllZonesData: TBRoteLocationZoneList | undefined = undefined;
	@observable roteGuildTemplateData: TBRoteDayZoneStatus[] = [];
	@observable roteZonesData: ITBRotePlanetsData[] | undefined = undefined;
	@observable savedMessages: TBSavedMessages[] = [];
	@observable isROETB: boolean = true;

	@observable currentPhase: number = 1;
	@observable numberOfDays: number = 6;
	@observable specialText: ISpecialText[] = [];
	@observable isAdmin: boolean = true;
	@observable reloading: boolean = false;
	@observable reloadingOrders: boolean = false;
	@observable savingAllOrders: boolean = false;
	private _retrySettings = 0;


	@observable allLocations: IROTEZoneType[] = ["top", "middle", "bottom", "bonus"];

	constructor(props: ICommandCenterROTEProps) {
		super(props);

		this.renderTabPhase = this.renderTabPhase.bind(this);
	}

	componentDidMount() {
		if (!this.props.user.isAdminTB || !this.props.user.isAdmin) {
			runInAction(() => {
				this.isAdmin = false;
			})
		}
		if (this.props.user.isAdminTB || this.props.user.isAdmin) {
			this.fetchTBData(false);
			this.fetchJSONTBData();
		}
	}

	componentWillUnmount() {
		clearTimeout(this._retrySettings);
	}

	private fetchJSONTBData() {
		const conflictZones = {} as { [zoneId: string]: any };
		roteData["t05D"].conflictZoneDefinition.forEach((z: any) => {
			conflictZones[z.zoneDefinition.zoneId as string] = z;
		});

		const data: ITBRotePlanetsData[] = roteData["t05D"].reconZoneDefinition.map((z: any) => {
			const zoneId = z.zoneDefinition.zoneId;
			const unitRarity = z.unitRarity;
			const unitRelicTier = z.unitRelicTier;
			const zoneConflict = conflictZones[z.zoneDefinition.linkedConflictId as string];

			return {
				zoneId: zoneId,
				forceAlignment: zoneConflict.forceAlignment,
				planetName: zoneConflict.zoneDefinition.name,
				requiredStars: unitRarity,
				requiredRelicLevel: unitRelicTier - 2,
			};
		});

		runInAction(() => {
			this.roteZonesData = data;
		});
		return data;
	}

	private async fetchTBData(refresh: boolean) {
		let gameDataJson = await BaseAPI.fetchTbGameData(this.props.user, this.props.gameData);

		runInAction(() => {
			this.tbAllZonesData = {} as TBRoteLocationZoneList;
			this.reloading = true;
			this.props.gameData.tbDataFromJson(gameDataJson);
			this.props.gameData.tbData.addDefinition(tbGameData.t05D, gameDataJson.tables, this.props.gameData.units!);
		});
		try {
			const settings = await BaseAPI.getGuildSetting(this.props.user, GUILD_TB_SAVED_MESSAGES);
			const guildDataSettings = await BaseAPI.getGuildSetting(this.props.user, GUILD_ALL_TB_COMMAND_CENTER);
			const zoneStatuses = await TBController.getRoteZonesStatus(this.props.user, refresh);

			const riseTbData: TerritoryBattleDefinition = this.props.gameData.tbData.definitions.find(d => d.id === "t05D")!;
			const tbmissionInfo = riseTbData.getTbMissionInfo();
			let data: ISpecialText[] = [];

			Array.from(tbmissionInfo.keys()).forEach(zoneId => {
				data.push({
					id: tbmissionInfo.get(zoneId)!.zoneId,
					text: tbmissionInfo.get(zoneId)!.getDescription(this.props.gameData.units ?? [])
				});

			});
			runInAction(() => {
				this.specialText = data;
				this.tbAllZonesData = zoneStatuses;
				if (guildDataSettings && guildDataSettings.length > 0 && !refresh) {
					this.roteGuildTemplateData = JSON.parse(guildDataSettings[0].value);
				}

				this.isROETB = !!zoneStatuses.top[0]?.zoneId?.includes('tb3');
			});
			// console.log('zoneStatuses: ', toJS(this.tbAllZonesData));
			// console.log('roteZonesData: ', toJS(this.roteZonesData));
			// console.log('tbGuildZoneDataTemplate: ', toJS(this.roteGuildTemplateData));

			if (settings && settings.length > 0) {
			    const allMsg = JSON.parse(settings[0].value);
				const allMsgFiltered: TBSavedMessages[] = allMsg.filter((x: TBSavedMessages) => x.message.length > 0);

				runInAction(() => {
					this.savedMessages = allMsgFiltered;
				});
			}

			runInAction(() => {
				this.reloading = false;
			});

		} catch (err: any) {
			Modal.error({
				title: i18next.t("common:ui.error_dlg_title"),
				content: <span>{err.errorMessage}</span>,
				maskClosable: false
			});
		}
	};

	private async sendTBCommands(dataJS: TBZoneCommands[]) {
		runInAction(() => this.reloadingOrders = true);
		

		await BaseAPI.setTBCommands(this.props.user, dataJS).then((response) => {
			runInAction(() => this.reloadingOrders = false);
			if (response.errorMessage) {
				return message.error(response.errorMessage);
			}
			const resp = response.responseMessage.toLowerCase();

			return message.info(resp);
		}).catch((err) => {
			runInAction(() => this.reloadingOrders = false);

			Modal.error({
				title: i18next.t("common:ui.error_dlg_title"),
				content: <span>{err.errorMessage}</span>,
				maskClosable: false
			});
		});
	}

	private async saveAllTBMessages() {
		const tbAllZonesData = toJS(this.roteGuildTemplateData);
		runInAction(() => this.savingAllOrders = true);

		await BaseAPI.setGuildSetting(this.props.user, GUILD_ALL_TB_COMMAND_CENTER, JSON.stringify(tbAllZonesData), true).then((response) => {
			const resp = response.responseMessage.toLowerCase();
			runInAction(() => this.savingAllOrders = false);
			if (response.errorMessage) {
				return message.error(resp);
			}

			return message.info(resp);
		}).catch((err) => {
			Modal.error({
				title: i18next.t("common:ui.error_dlg_title"),
				content: <span>{err.errorMessage}</span>,
				maskClosable: false
			});
		});
	}

	private hotZoneCommand(type: string) {
		switch (type) {
			case 'Focused':
				return 'Focus';
			case 'Ignored':
				return 'Ignore';
			case 'Nocommand':
				return 'No command';
			case '':
			default:
				return 'None';
		}
	}

	private hotZoneCommandNo(type: string | number): number {
		if (!isNaN(type as number) && type !== '') {
			return type as number;
		}
		switch (type) {
			case 'Focused':
				return 2;
			case 'Ignored':
				return 3;
			case 'Nocommand':
				return 1;
			case '':
			default:
				return 0;
		}
	}


	private hotZoneCommandStr(type: number | string): string {
		if (typeof type === 'string') {
			return type;
		}

		switch (type) {
			case 2:
				return 'Focused';
			case 3:
				return 'Ignored';
			case 1:
				return 'Nocommand';
			case 0:
			default:
				return '';
		}
	}

	@action
	private onTbMessageSave(zoneId: string, phase: number, location: IROTEZoneType, msg: string) {
		const allMessages = [...this.savedMessages];

		allMessages.push({
			id: uuid(),
			zoneId,
			phase,
			location,
			message: msg
		})

		this.savedMessages = allMessages;

		BaseAPI.setGuildSetting(this.props.user, GUILD_TB_SAVED_MESSAGES, JSON.stringify(allMessages), true).then((response) => {
			const resp = response.responseMessage.toLowerCase();
			runInAction(() => this.reloadingOrders = false);
			if (response.errorMessage) {
				return message.error(resp);
			}

			return message.info(resp);
		}).catch((e) => message.error(e));
	}

	@action
	private onTbMessageDelete(id: string) {
		const allMsg = [...this.savedMessages];
		const msgIdx = allMsg.findIndex((x) => x.id === id);

		allMsg.splice(msgIdx, 1);

		this.savedMessages = allMsg;

		BaseAPI.setGuildSetting(this.props.user, GUILD_TB_SAVED_MESSAGES, JSON.stringify(allMsg), true).then((response) => {
			const resp = response.responseMessage.toLowerCase();
			runInAction(() => this.reloadingOrders = false);
			if (response.errorMessage) {
				return message.error(resp);
			}

			return message.info(resp);
		}).catch((e) => message.error(e));
	}

	private overlayMenu(data: TBReconZoneStatus, currentPhase: number, location: IROTEZoneType, zoneType: TZoneType) {
		return <Menu className={styles.dropMenu}>
			{this.savedMessages.map((s) => {
				return <Menu.Item title={s.message} key={s.id}>
					<div className={styles.rowMenu}>
						<Button type={"text"} className={styles.btn} onClick={() => {
							const newData: TBReconZoneStatus = {
								zoneId: data.zoneId,
								phase: data.phase,
								location: data.location,
								specialText: data.specialText,
								commandMessage: s.message,
								commandState:data.commandState,
								zoneStatus: data.zoneStatus
							};
							runInAction(() => {
								this.handleUpdateTemplateCards(newData, currentPhase, location, zoneType);
							});
						}}>
							{s.message}
						</Button>
						<Button size={"small"} danger={true} onClick={(e) => {
							e.stopPropagation();
							this.onTbMessageDelete(s.id);
						}}>
							<FontAwesomeIcon icon={faTrash}/>
						</Button>
					</div>
				</Menu.Item>;
			})}
		</Menu>;
	}

	private renderTypeCards(zoneType: TZoneType, data: TBRoteAllZones[], location: IROTEZoneType, currentPhase: number) {
		const zoneData = data.find((z) => z.zoneType === zoneType);
		const allCards: JSX.Element[] = [];

		if (zoneData) {
			const allTypeData = zoneData.data;

			for (let i = 0; i < allTypeData.length; i++) {
				const typeData = allTypeData[i];

				if (typeData) {
					const specialText = this.specialText.find((s) => s.id === typeData.zoneId);
					const specialTitle = typeData.specialText !== null ? typeData.specialText : specialText?.text;

					const dataCard = <div key={`DataCard-${i}`} className={`${styles.orderRoetb}`}>

					<Card
						title={`${specialTitle !== undefined ? `${specialTitle}` : ''}`}
						bordered={true}
						className={"antCard-header"}>
						<div className={styles.contentRow}>
							<span className={styles.label}>
								Message:
							</span>
							<Input
								size={"middle"}
								maxLength={74}
								className={`${styles.select} ${styles['form-item']}`}
								value={typeData.commandMessage}
								addonBefore={
									<Button type={"text"} size={"middle"} className={styles.btnSave}
											disabled={ (typeData.commandMessage.length) < 1}
											onClick={() => {
												this.onTbMessageSave(typeData.zoneId, typeData.phase, location, typeData.commandMessage);
											}}>
										<FontAwesomeIcon icon={faSave}/>
									</Button>
								}
								addonAfter={
									<Dropdown
										overlay={this.overlayMenu(typeData, currentPhase, location, zoneType)}
										trigger={['click']}
										arrow={false}
										placement={"bottomRight"}
										disabled={this.savedMessages.length === 0}
									>
										<FontAwesomeIcon icon={faAngleDown} className={styles.btnArrow}/>
									</Dropdown>
								}
								placeholder={typeData.commandMessage ?? 'Please insert a message'}
								autoFocus={false}
								allowClear={true}
								onChange={(event) => {
									const newData: TBReconZoneStatus = {
										phase: typeData.phase,
										location: typeData.location,
										commandMessage: event.target.value,
										commandState: typeData.commandState,
										zoneId: typeData.zoneId,
										specialText: typeData.specialText,
										zoneStatus: typeData.zoneStatus
									};
									runInAction(() => {
										this.handleUpdateTemplateCards(newData, currentPhase, location, zoneType);
									});
								}}
							/>
						</div>
						<div className={styles.contentRow}>
							<span className={styles.label}>
								State:
							</span>
							<Select
								value={this.hotZoneCommand(typeData.commandState ?? '')}
								className={styles.select}
								onChange={(value) => {
									const newData: TBReconZoneStatus = {
										phase: typeData.phase,
										location: typeData.location,
										commandMessage: typeData.commandMessage ?? '',
										commandState: this.hotZoneCommandStr(value),
										zoneId: typeData.zoneId,
										specialText: typeData.specialText,
										zoneStatus: typeData.zoneStatus
									};

									return runInAction(() => {
										this.handleUpdateTemplateCards(newData, currentPhase, location, zoneType);
									});
								}}
								options={[
									{
										value: 0,
										label: 'None',
									},
									{
										value: 1,
										label: 'No command',
									},
									{
										value: 2,
										label: 'Focus',
									},
									{
										value: 3,
										label: 'Ignore',
									},
								]}
							/>
						</div>
					</Card>
					</div>;

					allCards.push(dataCard);
				}
			}
		}

		return allCards;
	}

	private renderLocations(zoneDataPhase: TBRoteZoneData, location: IROTEZoneType, phase: number) {

			const zoneDataPerPhase = zoneDataPhase.data ?? [];
			const allSMData = zoneDataPerPhase.filter((x) => x.zoneType === "special_mission");
			const hasSMData = allSMData.map(x => x.data)[0]?.length > 0;

			return (
				<div className={styles.zoneContainer}>
					<Divider orientation="left" plain>
						Zone | Planet: {zoneDataPhase.planetName} - R{zoneDataPhase.requiredRelicLevel}
					</Divider>
					<div className={styles.gridRow}>
						{this.renderTypeCards("zone", zoneDataPerPhase, location, phase)}
					</div>
					<Divider orientation="left" plain>
						Platoons for Planet: {zoneDataPhase.planetName} - R{zoneDataPhase.requiredRelicLevel}
					</Divider>
					<div className={styles.gridRow}>
						{this.renderTypeCards("platoon", zoneDataPerPhase, location, phase)}
					</div>
					<Divider orientation="left" plain>
						Combat Missions for Planet: {zoneDataPhase.planetName} - R{zoneDataPhase.requiredRelicLevel}
					</Divider>
					<div className={styles.gridRow}>
						{this.renderTypeCards("combat_mission", zoneDataPerPhase, location, phase)}
					</div>
					{hasSMData && (<Divider orientation="left" plain>
						Special Missions for Planet: {zoneDataPhase.planetName} -
						R{zoneDataPhase.requiredRelicLevel}
					</Divider>)}
					{hasSMData && (<div className={styles.gridRow}>
						{this.renderTypeCards("special_mission", zoneDataPerPhase, location, phase)}
					</div>)}
				</div>
			);
	}

	@action
	private handleUpdateTemplateCards = (newData: TBReconZoneStatus, currentPhase: number, location: IROTEZoneType, zoneType: TZoneType) => {
		const dataRote = this.roteGuildTemplateData.find(x => x.phase === currentPhase)?.zones[location];
		if (!dataRote) {
			return;
		}
		if (Array.isArray(dataRote)) {
			// Processing newData.zoneId to get base ID till "_bonus"
			const newDataBaseId = newData.zoneId.split('_bonus')[0] + '_bonus';

			const getBonusPlanetIdx = dataRote.findIndex((x) => {
				// Process the zoneId from dataRote to stop at "_bonus"
				const dataRoteBaseId = x.zoneId?.split('_bonus')[0] + '_bonus';
				// Compare processed IDs
				return dataRoteBaseId === newDataBaseId;
			});

			if (getBonusPlanetIdx === -1) {
				return console.error(`No data found for ROTE TB! Looking for: ${newDataBaseId}, Found: ${dataRote[0]?.zoneId}`);
			}

			this.updateTemplatePathData(dataRote[getBonusPlanetIdx], zoneType, newData);
		} else {
			this.updateTemplatePathData(dataRote, zoneType, newData);
		}
	};

	private updateTemplatePathData = (roteData: TBRoteZoneData, zoneType: TZoneType, newData: TBReconZoneStatus) => {
		const templatePath = roteData.data.find((x) => x.zoneType === zoneType);
		if (templatePath?.data) {
			// Update the specific part of the template with the new data, if the zoneId matches.
			return templatePath.data = templatePath.data.map(x => x.zoneId === newData.zoneId ? newData : x);
		}
	}

	private getBonusData(templateData: TBRoteLocationZoneStatus | undefined) {
		let bonusData: TBRoteZoneData[] = [];

		if (!templateData) {
			return [];
		}

		if (Array.isArray(templateData?.bonus)) {
			bonusData = templateData.bonus;
		} else if (templateData?.bonus) {
			bonusData = [templateData.bonus];
		}

		return bonusData.filter((x) => x !== undefined && x !== null);
	}

	private renderTabPhase() {
		const tabs: any[] = [];

		for (let i = 0; i < this.numberOfDays; i++) {
			const phaseNo = i + 1;
			const templateData = this.roteGuildTemplateData.find((z) => z.phase === this.currentPhase)?.zones;

			const templateBonusData = this.getBonusData(templateData);
			const tab = <TabPane
				tab={
					<span title={`Phase ${i + 1}`}>
						<FontAwesomeIcon icon={faCalendarDay} className={styles.icon}/>
						Day {phaseNo}
					</span>
				}
				key={`${phaseNo}`}
			>
				<div className={styles.tabContainer}>
					{this.roteZonesData && <CCPlanetSelection
						{...this.props}
						data={this.roteZonesData}
						templateZoneLocation={this.roteGuildTemplateData.find((x) => x.phase === i + 1)?.zones}
						phase={phaseNo}
						onChangePlanet={(location, zoneData) => {
							runInAction(() => {
								const template = this.roteGuildTemplateData.filter((x) => x.phase !== phaseNo);
								const currentViewedTemplate = this.roteGuildTemplateData.find((x) => x.phase === phaseNo);
								if (this.tbAllZonesData) {

									if (zoneData) {
										const zoneIdTrim = zoneData.zoneId?.replace('_recon01', '');
										const dataZonesType = this.tbAllZonesData[location].find((x) => x.zoneId === zoneIdTrim);

										if (!dataZonesType) {
											return message.error(`No data found for ROTE TB! Found: ${this.tbAllZonesData[location][0]?.zoneId}`);
										}

										const updatedPhase: TBRoteDayZoneStatus = {
											phase: phaseNo,
											zones: {
												...currentViewedTemplate?.zones,
												[location]: {
													phase: phaseNo,
													location: location,
													planetName: zoneData?.planetName,
													forceAlignment: zoneData?.forceAlignment,
													requiredRelicLevel: zoneData?.requiredRelicLevel,
													requiredStars: zoneData?.requiredStars,
													zoneId: zoneData?.zoneId,
													data: toJS(dataZonesType.data)
												}
											}
										};

										const updatedTemplate: TBRoteDayZoneStatus[] = template.concat(updatedPhase);

										// console.log('updatedTemplate: ', toJS(updatedTemplate));
										if (updatedTemplate) {
											this.roteGuildTemplateData = updatedTemplate;
										}
									} else {
										const updatedPhase: TBRoteDayZoneStatus = {
											phase: phaseNo,
											zones: {
												...currentViewedTemplate?.zones,
												[location]: undefined
											}
										};

										const updatedTemplate: TBRoteDayZoneStatus[] = template.concat(updatedPhase);

										if (updatedTemplate) {
											this.roteGuildTemplateData = updatedTemplate;
										}
									}
								}
							});
						}}
						onChangeBonus={(location, zonesData) => {
							runInAction(() => {
								const template = this.roteGuildTemplateData.filter((x) => x.phase !== phaseNo);
								const currentViewedTemplate = this.roteGuildTemplateData.find((x) => x.phase === phaseNo);

								if (!currentViewedTemplate) {
									console.error('No template found for the current phase:', phaseNo);
									return;
								}

								let updatedPhase = {
									phase: phaseNo,
									zones: {
										...toJS(currentViewedTemplate.zones),
										bonus: [] as TBRoteZoneData[]
									}
								};

								if (this.tbAllZonesData && zonesData.length > 0) {
									// Use a Map to collect the most recent state of the bonus zones
									const bonusZonesMap = new Map();

									// Populate the map with the new zonesData
									zonesData.forEach((zoneData) => {
										const zoneIdTrim = zoneData.zoneId?.replace('_recon01', '');
										const dataZonesType = this.tbAllZonesData?.bonus.find(x => x.zoneId === zoneIdTrim);

										if (!dataZonesType) {
											console.error(`No data found for ROTE TB! Looking for: ${zoneIdTrim}, Found: ${this.tbAllZonesData?.bonus[0]?.zoneId}`);
											return; // Use `return` to skip this iteration of forEach
										}

										bonusZonesMap.set(zoneData.planetName, {
											phase: phaseNo,
											location: location,
											planetName: zoneData.planetName,
											forceAlignment: zoneData.forceAlignment,
											requiredRelicLevel: zoneData.requiredRelicLevel,
											requiredStars: zoneData.requiredStars,
											zoneId: zoneData.zoneId,
											data: toJS(dataZonesType.data)
										});
									});

									// Convert the map back to an array and assign to updatedPhase.zones.bonus
									updatedPhase.zones.bonus = Array.from(bonusZonesMap.values());
								} else {
									// If zonesData is empty, ensure bonus is also empty
									updatedPhase.zones.bonus = [];
								}

								let updatedTemplate = template.concat(updatedPhase);

								if (updatedTemplate.length > 0) {
									this.roteGuildTemplateData = updatedTemplate;
								}
							});
						}}
					/>}
				</div>

				<div className={`${styles.tabContainer} ${styles.column}`}>
					{templateData?.top &&
						<Collapse defaultActiveKey={['1']} className={styles.panel}>
							<Panel header={`DS zone: Planet ${templateData?.top?.planetName} - R${templateData.top.requiredRelicLevel}`} key="1" className={"panel-header"}>
								{this.renderLocations(templateData.top, "top", phaseNo)}
							</Panel>
						</Collapse>
					}
					{templateData?.middle &&
						<Collapse defaultActiveKey={undefined} className={styles.panel}>
							<Panel header={`Mixed zone: Planet ${templateData?.middle?.planetName} - R${templateData.middle.requiredRelicLevel}`} key="2" className={"panel-header"}>
								{this.renderLocations(templateData.middle, "middle", phaseNo)}
							</Panel>
						</Collapse>
					}
					{templateData?.bottom &&
						<Collapse defaultActiveKey={undefined} className={styles.panel}>
							<Panel header={`LS zone: Planet ${templateData?.bottom?.planetName} - R${templateData.bottom.requiredRelicLevel}`} key="3" className={"panel-header"}>
								{this.renderLocations(templateData.bottom, "bottom", phaseNo)}
							</Panel>
						</Collapse>
					}
					{templateBonusData.length > 0 && this.renderBonusZones(templateBonusData, phaseNo)}
				</div>
			</TabPane>;

			tabs.push(tab);
		}

		return tabs;
	}

	private renderBonusZones(bonusDataZones: TBRoteZoneData[], currentPhase: number) {
		if (bonusDataZones && bonusDataZones.length > 0) {
			let bonusData: JSX.Element[] = [];

			for (let i = 0; i < bonusDataZones.length; i++) {
				const bonus = bonusDataZones[i];

				bonusData.push(
					<Collapse defaultActiveKey={undefined} className={styles.panel} key={i}>
						<Panel key={bonus.zoneId ?? i} header={`Bonus zone: Planet ${bonus.planetName} - R${bonus.requiredRelicLevel}`} className={"panel-header"}>
							{this.renderLocations(bonus, "bonus", currentPhase)}
						</Panel>
					</Collapse>
				);
			}

			return bonusData;
		}
	}

	private getLocationNo(location: IROTEZoneType) {
		switch (location) {
			case 'top':
				return 2;
			case 'middle':
				return 3;
			case 'bottom':
				return 1;
			case 'bonus':
				return 4;
			default:
				return 0;
		}
	}

	private pushCommands = async () => {
		let outOfDate = false;
		if (!this.props.user.currentPlayer || !this.props.user.currentPlayer.territoryBattleData)
		{
			outOfDate = true;
		}
		if (this.props.user.currentPlayer?.territoryBattleData?.currentRoundEndTime)
		{
			let endTime = moment.unix(this.props.user.currentPlayer.territoryBattleData.currentRoundEndTime);
			if (endTime.diff(moment(), "minutes") < 0)
				outOfDate = true;
		}
		if (outOfDate)
			await BaseAPI.fetchTbData(this.props.user, true);
		
		const dataJS = toJS(this.roteGuildTemplateData.filter((x) => x.phase === this.currentPhase));

		const zones = dataJS[0]?.zones || {};
		const allZonesCurrentPhase: TBRoteZoneData[] = Object.values(zones).reduce<TBRoteZoneData[]>((acc, zone) => {
			if (Array.isArray(zone)) {
				// Flatten and concatenate if the element is an array
				return acc.concat(zone);
			} else {
				// Add the single object to the accumulated array
				return acc.concat(zone);
			}
		}, []);


		const allData: TBZoneCommands[] = allZonesCurrentPhase.flatMap(allZoneData => {
			const zoneData = allZoneData.data;

			return zoneData.flatMap((zData) => {
				const zoneType = zData.data;

				return zoneType.map((reconZoneData) => {
					const { zoneId, commandState, commandMessage: message, specialText } = reconZoneData;
					const { location, phase } = allZoneData;

					return {
						zoneId: zoneId,
						message: message,
						commandState: this.hotZoneCommandNo(commandState),
						specialText: specialText,
						phase: phase,
						location: this.getLocationNo(location ?? 'top'),
					};
				});
			});
		});

		// console.log('allData: ', allData);

		return this.sendTBCommands(allData);
	};


	render() {
		if (this.errorGuildFetch !== undefined) {
			return (
				<BasicLayout {...this.props} requiredApiRights={[TBPlayers.PERMISSION_KEY]}>
					<LoadingSpinner size={"large"} error={true} spinning={false} text={this.errorGuildFetch}/>
				</BasicLayout>
			);
		}
		if (!this.props.user.currentPlayer || !this.tbAllZonesData || !this.roteZonesData) {
			return <BasicLayout {...this.props} requiredApiRights={[TBPlayers.PERMISSION_KEY]}>
				<LoadingSpinner size={"large"} spinning={true} text={"Loading data.."}/>
			</BasicLayout>;
		}
		let outOfDate = true;
		if (this.props.user.currentPlayer?.territoryBattleData?.currentRoundEndTime) {
			let endTime = moment.unix(this.props.user.currentPlayer!.territoryBattleData!.currentRoundEndTime);
			outOfDate = endTime.diff(moment(), "minutes") < 0;
		}

		return (
			<BasicLayout {...this.props} requiredApiRights={[TBPlayers.PERMISSION_KEY]}
						 extraRefresh={() => this.fetchTBData(true)}>
				<div className={styles.container}>
					<div className={styles.header}>
						<div className={styles.bg}/>
						<Title level={3} className={styles.title}>
							Command Center
						</Title>
						<div className={styles.row}>
							<Button type={"default"} className={styles.btn}
									title={'Go back to selection of command center'} onClick={() => this.props.back()}>
								<FontAwesomeIcon icon={faArrowAltLeft} className={styles.icon}/>
								Back on selection screen
							</Button>
							{this.isAdmin && <Popconfirm
								title={`Do you want to break the game connection and get fresh data?`}
								onConfirm={() => this.fetchTBData(true)}
								key={'popconfirmFetchTBData'}
								onCancel={() => this.fetchTBData(false)}
								okText="Yes"
								cancelText="No"
							>
								<Button type={"default"} className={styles.btn}
										title={'Sync the platoons with the game'}>
									<FontAwesomeIcon icon={faSyncAlt} spin={this.reloading} className={styles.icon}/>
									Get data from the game
								</Button>
							</Popconfirm>}
							{this.isROETB && (<Button
								className={styles.btn}
								disabled={this.savingAllOrders}
								title={'Save all the changes as a template for the guild'}
								onClick={() => {
									this.saveAllTBMessages()
								}}>
								<FontAwesomeIcon icon={faSyncAlt} spin={this.savingAllOrders} className={styles.icon}/>
								Save all the changes!
							</Button>)}
						</div>
					</div>
					{outOfDate && !this.reloading &&
						<Alert message="Please refresh the data!" type="info"
							   style={{width: '100%', textAlign: 'center'}}/>}
					{this.reloading &&
						<Alert message="Patience you must have, my young padawan! Refreshing, the data is!" type="info"
							   style={{width: '100%', textAlign: 'center'}}/>}
					{!this.isAdmin &&
						<Alert message="You need to be an admin or an officer to access this page!" type="warning"
							   style={{width: '100%', textAlign: 'center'}}/>}

					{!this.isROETB && this.tbAllZonesData && this.roteZonesData && this.roteZonesData.length > 0 && (<div className={styles.body}>
						<div className={styles.txtNoRote}>
							The TB data for your guild on our server isn't updated for ROTE!
							<br />Please refresh the data from the game while your guild is actively running ROTE.
						</div>
					</div>)}
					{this.isROETB && this.tbAllZonesData && <div className={styles.body}>
						<Tabs
							defaultActiveKey="`tab-phase1"
							size={"large"}
							onChange={(event) => runInAction(() => {
								this.currentPhase = Number(event);
							})}
							destroyInactiveTabPane={false}
							centered={false}
							tabBarExtraContent={this.numberOfDays && <Popconfirm
								title={`Do you wish to push for day ${this.currentPhase} targeting your selected planets? Please note, this will overwrite all existing orders in the game and may take several minutes to complete."`}
								onConfirm={() => this.pushCommands()}
								key={'confirmPushAllTBOrders'}
								onCancel={() => {return;}}
								okText="Yes, Push all the orders"
								cancelText="Cancel, I changed my mind"
							>
							    <Button type={'primary'}>
							        <FontAwesomeIcon icon={faSyncAlt} spin={this.reloadingOrders} className={styles.icon}/>
							        Send the orders for Day: {this.currentPhase}
							    </Button>
							</Popconfirm>
							}
						>
							{this.renderTabPhase()}
						</Tabs>
					</div>}
				</div>

			</BasicLayout>
		);
	}
}

